import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/layout/dashboard-layout/dashboard-layout/dashboard-layout.component';

const route: Route[] = [
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'vehicles',
        loadChildren: () =>
          import('./modules/vehicles/vehicles.module').then(
            ({ VehiclesModule }) => VehiclesModule
          ),
      },
      {
        path: 'features',
        loadChildren: () =>
          import('./modules/features/features.module').then(
            ({ FeaturesModule }) => FeaturesModule
          ),
      },
      {
        path: 'files',
        loadChildren: () =>
          import('./modules/fileType/fileType.module').then(
            ({ FileTypeModule }) => FileTypeModule
          ),
      },
      {
        path: 'vehicle-files',
        loadChildren: () =>
          import('./modules/files/vehicle-files.module').then(
            ({ VehicleFilesModule }) => VehicleFilesModule
          ),
      },
      {
        path: 'fine',
        loadChildren: () =>
          import('./modules/fine/fine.module').then(
            ({ FineModule }) => FineModule
          ),
      },
      {
        path: 'reminderTypes',
        loadChildren: () =>
          import('./modules/reminderType/reminderType.module').then(
            ({ ReminderTypeModule }) => ReminderTypeModule
          ),
      },
      {
        path: 'reminder',
        loadChildren: () =>
          import('./modules/reminder/reminder.module').then(
            ({ ReminderModule }) => ReminderModule
          ),
      },
      {
        path: 'maintenance-type',
        loadChildren: () =>
          import('./modules/maintenance-type/maintenance-type.module').then(
            ({ MaintenanceTypeModule }) => MaintenanceTypeModule
          ),
      },
      {
        path: 'maintenance',
        loadChildren: () =>
          import('./modules/maintenance/maintenance.module').then(
            ({ MaintenanceModule }) => MaintenanceModule
          ),
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(route)],
  exports: [RouterModule],
})
export class FleetRoutingModule {}
