<div
  class="side-nav flex-row-start"
  #sideNav
  *ngIf="selectedMainItem$ | async as selectedMainItem"
  [ngClass]="{
    expanded:
      sideNavStatus === sideNavStatusEnum.expanded &&
      !!selectedMainItem.items.length,
    'expanded-over':
      (sideNavStatus === sideNavStatusEnum.mouseenter) &&
      !!selectedMainItem.items.length
  }"
  (mouseenter)="hoverExpandNavbar($event)"
  (mouseleave)="hoverExpandNavbar($event)"
>
  <div
    class="side-nav__collapse-btn"
    #arrowEl
    [toggleSideNav]="sideNav"
    [(status)]="sideNavStatus"
    [(canHover)]="canHover"
    [hidden]="!selectedMainItem.items.length"
  >
    <i
      class="pi"
      [ngClass]="
        sideNavStatus === sideNavStatusEnum.collapse ||
        sideNavStatus === sideNavStatusEnum.mouseleave
          ? 'pi-angle-right'
          : 'pi-angle-left'
      "
    >
    </i>
  </div>
  <div class="icons-bar" icons-bar>
    <div class="icons-bar-content flex-col-between">
      <!-- --------------------------------------- TOP NAV --------------------------------------- -->
      <div class="top-nav">
        <ul class="flex-col-center gap-10">
          <ng-container
            *ngFor="let item of mainLinks | async; let index = index"
          >
            <a
              *ngIf="item.isShown && item.direction === 'TOP'"
              [routerLink]="item.routerLink ? item.routerLink : null"
            >
              <li
                [pTooltip]="item?.tooltip ?? '' | translate"
                [selectedItem]="sideNav"
                [arrowEl]="arrowEl"
                [(status)]="sideNavStatus"
                (statusChange)="setSelectedItem(item.id)"
                [(canHover)]="canHover"
                [ngClass]="{
                  active:
                    (selectedMainItem?.id === item.id &&
                      item.routerLink &&
                      arla.isActive) ||
                    (selectedMainItem?.id === item.id && item.expanded)
                }"
                routerLinkActive="active"
                #arla="routerLinkActive"
                [routerLinkActiveOptions]="{
                  exact: item.routerLinkExact ?? false
                }"
              >
                <sxw-svg-icon
                  *ngIf="item.icon"
                  [class]="'icons'"
                  width="30"
                  height="30"
                  [name]="item.icon"
                  [fillColor]="
                    selectedMainItem?.id === item.id ? '#fff' : '#000'
                  "
                ></sxw-svg-icon>
              </li>
            </a>
          </ng-container>
        </ul>
      </div>

      <!-- --------------------------------------- BOTTOM NAV --------------------------------------- -->
      <div class="bottom-nav">
        <ul class="flex-col-center gap-10">
          <ng-container
            *ngFor="let item of mainLinks | async; let index = index"
          >
            <a [routerLink]="item.routerLink ? item.routerLink : null">
              <li
                [pTooltip]="item?.tooltip ?? '' | translate"
                tooltipStyleClass="sxw-tooltip"
                *ngIf="item.direction === 'BTN'"
                [selectedItem]="sideNav"
                [arrowEl]="arrowEl"
                [(status)]="sideNavStatus"
                (statusChange)="setSelectedItem(item.id)"
                [(canHover)]="canHover"
                [ngClass]="{
                  active:
                    (selectedMainItem?.id === item.id &&
                      item.routerLink &&
                      arla.isActive) ||
                    (selectedMainItem?.id === item.id && item.expanded)
                }"
                #arla="routerLinkActive"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{
                  exact: item.routerLinkExact ?? false
                }"
              >
                <sxw-svg-icon
                  [class]="'icons'"
                  *ngIf="item.icon"
                  width="30"
                  height="30"
                  [name]="item.icon"
                  [fillColor]="
                    selectedMainItem?.id === item.id ? '#fff' : '#000'
                  "
                ></sxw-svg-icon>
              </li>
            </a>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <div #linkNav class="links-nav hide-mobile" links-nav>
    <div class="items flex-col-center">
      <ul>
        <ng-container
          *ngFor="let subItem of selectedMainItem.items"
          [ngTemplateOutlet]="subItems"
          [ngTemplateOutletContext]="{ $implicit: subItem, depth: 0 }"
        >
        </ng-container>
      </ul>
    </div>
  </div>
</div>

<!-- Render SubMenuItems and nested SubMenuItems -->
<ng-template #subItems let-subItem let-depth="depth">
  <a
    [routerLink]="subItem.routerLink ? subItem.routerLink : null"
    class="nav-item-link"
  >
  <li
    class="flex-row-between gap-30 nav-item"
    *ngIf="subItem.isShown"
    (click)="toggleExpandMenuItem(subItem)"
    [ngClass]="{ open: subItem.expanded }"

    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: false }"
  >
    <span translate>sidenav.{{ subItem.label }}</span>
    <div
      class="expand-icon"
      [ngClass]="{ open: subItem.expanded }"
      *ngIf="subItem?.items?.length"
    >
      <sxw-svg-icon name="down-arrow" width="10"></sxw-svg-icon>
    </div>
  </li>
  </a>

  <ng-container *ngIf="subItem.items.length">
    <ul
      class="sub-menu sub-menu-{{depth | json}}"
      [@expand-nav-item]="subItem.expanded ? 'expanded' : 'collapsed'"
      [ngClass]="{ open: subItem.expanded }"
    >
      <ng-container
        *ngFor="let item of subItem.items"
        [ngTemplateOutlet]="subItems"
        [ngTemplateOutletContext]="{ $implicit: item, depth: depth+1 }"
      >
      </ng-container>
    </ul>
  </ng-container>
</ng-template>
