import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, inject, Input, Output } from "@angular/core";
import { SideNavStatusEnum } from "../interfaces/side-nav-status.enum";
import { DOCUMENT } from "@angular/common";

@Directive({})
export abstract class ToggleSideNav {
  protected readonly MOBILE_WIDTH = 500;
  protected readonly SIDE_NAV_DEFAULT_WIDTH = 320;
  protected readonly ACTION_BAR_DEFAULT_WIDTH = 50;
  // protected readonly LINKS_NAV_DEFAULT_WIDTH = this.SIDE_NAV_DEFAULT_WIDTH - this.ACTION_BAR_DEFAULT_WIDTH;
  protected document = inject(DOCUMENT);
  protected el = inject<ElementRef<HTMLDivElement>>(ElementRef<HTMLElement>);

  @Input() sideNav?: HTMLDivElement
  @Input() status?: SideNavStatusEnum;
  @Input() arrowEl?: HTMLDivElement;
  @Input() canHover = false;
    
  get bodyWidth() {
    return this.document.body.offsetWidth;
  }

  get actionBarWidth() {
    return (this.sideNav?.querySelector<HTMLElement>('[icons-bar]')?.offsetWidth ?? this.ACTION_BAR_DEFAULT_WIDTH) - 10
  }

  get linksNavWidth() {
    return this.sideNav?.querySelector<HTMLElement>('[links-nav]')?.offsetWidth || this.SIDE_NAV_DEFAULT_WIDTH - (this.actionBarWidth + 10);
  }

  get collabsedBtn() {
    return this.arrowEl ? this.arrowEl : this.el.nativeElement;
  }

  @Output() statusChange = new EventEmitter<SideNavStatusEnum>();
  @Output() canHoverChange = new EventEmitter<boolean>();

  @HostListener('click')
  expandNavbar() {
    this.calcSideNavStatus()
    this.calcActionBtnPosition();
    this.statusChange.emit(this.status);
    this.canHoverChange.emit(this.canHover);
  }

  protected calcSideNavStatus() {
    if(this.bodyWidth <= this.MOBILE_WIDTH) {
      if(this.status === SideNavStatusEnum.collapse ||
          this.canHover && this.status === SideNavStatusEnum.mouseenter || 
          this.status === SideNavStatusEnum.mouseleave
        ) {
        this.status = SideNavStatusEnum.mouseenter;
        this.canHover = false
      } 
      else if((!this.canHover && this.status === SideNavStatusEnum.mouseenter || this.status === SideNavStatusEnum.expanded)){
        this.status = SideNavStatusEnum.collapse
        this.canHover = true
      }
    } else {
      if (this.status === SideNavStatusEnum.expanded) {
        this.status = SideNavStatusEnum.collapse
        this.canHover = true;
        } else if (
          this.status === SideNavStatusEnum.collapse || 
          this.status === SideNavStatusEnum.mouseenter || 
          this.status ===    SideNavStatusEnum.mouseleave
        ) {
          this.status = SideNavStatusEnum.expanded
          this.canHover = false;
        }
    }
  }

  protected calcActionBtnPosition() {
    let actionLeftPosition = +this.collabsedBtn.style.insetInlineStart.split('px')[0] || this.actionBarWidth
    if(this.status === SideNavStatusEnum.collapse) {
      actionLeftPosition = this.actionBarWidth
    } else {
      actionLeftPosition += this.linksNavWidth
    }
    this.collabsedBtn.style.insetInlineStart = `${actionLeftPosition}px`
  }
}
