export default `
    id
    startedAt
    endedAt
    user{
        type
        personalInfo{
            fullName
        }
    }
    createdAt
`