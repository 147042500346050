import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/layout/dashboard-layout/dashboard-layout/dashboard-layout.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'plan',
        loadChildren: () =>
          import('./modules/plan/plan.module').then(
            ({ PlanModule }) => PlanModule
          ),
      },
      {
        path: 'subscriptions',
        loadChildren: () =>
          import('./modules/subscriptions/subscriptions.module').then(
            ({ SubscriptionsModule }) => SubscriptionsModule
          ),
      },
      {
        path: 'voucher',
        loadChildren: () =>
          import('./modules/vouchers/voucher.module').then(
            ({ VoucherModule }) => VoucherModule
          ),
      },
      {
        path: 'transactions',
        loadChildren: () =>
          import('./modules/transactions/transactions.module').then(
            ({ TransactionsModule }) => TransactionsModule
          ),
      },
      {
        path: 'expense',
        loadChildren: () =>
          import('./modules/expenses/expenses.module').then(
            ({ ExpensesModule }) => ExpensesModule
          ),
      },
      {
        path: 'working-records',
        loadChildren: () =>
          import(
            './modules/recorded-working-hours/recorded-working-hours.module'
          ).then(
            ({ RecordedWorkingHoursModule }) => RecordedWorkingHoursModule
          ),
      },
      {
        path: 'salary-records',
        loadChildren: () =>
          import('./modules/salary-records/salary-records.module').then(
            ({ SalaryRecordsModule }) => SalaryRecordsModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubscriptionRoutingModule {}
