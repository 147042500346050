import {Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { NotificationService } from '../../services/notifications/notification.service';
import {
  OverlayPanelComponent
} from '../../../shared/modules/overlay-panel/components/overlay-panel/overlay-panel.component';
import {Subject, takeUntil } from 'rxjs';
import { NotificationInterface } from "src/app/kernel/notifications/interfaces/notification.interface";
import { Router } from "@angular/router";

@Component({
  selector: 'sxw-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @Input() notificationsTitle = 'Notifications'

  notifications: NotificationInterface[] = [];
  totalPages = 0;
  loading = true;
  page = this.notificationService.getPage();
  newNotificationsCount = 0;

  private rpp = 5;

  @ViewChild('notificationPanel') notificationPanel!: OverlayPanelComponent;
  @ContentChild('notificationsTriggerTemplate') notificationsTriggerTemplate!: TemplateRef<any>;
  @ContentChild('notificationTemplate') notificationTemplate!: TemplateRef<any>;
  @ContentChild('notificationsHeaderTemplate') notificationsHeaderTemplate!: TemplateRef<any>;


  private _unsubscribe = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.addFirebaseToken();
    this.listenToForgroundMessages();
    this.listenToBackGroundMessages();
    this.handleNotifications();
  }

  private handleNotifications() {
    this.fetchNotifications();
    this.observeNotifications();
    this.observePage();
    this.observeNewNotificationsCount();
    this.observeTotalNotificationPages();
  }

  fetchNotifications(reset = true) {
    this.loading = true;

    this.notificationService.getNotifications(this.page, this.rpp, reset)
    .pipe(takeUntil(this._unsubscribe)).subscribe({
      next: () => {
        this.notificationService.setInitNotifications(true);
        this.loading = false;
      }
    })
  }

  observePage() {
    this.notificationService.page$
    .pipe(takeUntil(this._unsubscribe))
    .subscribe(page => this.page = page);
  }

  private addFirebaseToken() {
    this.notificationService.addFirebaseToken()
    .pipe(takeUntil(this._unsubscribe))
    .subscribe();
  }

  private listenToForgroundMessages() {
    this.notificationService.messages
    .pipe(takeUntil(this._unsubscribe))
    .subscribe({
      next: (data) => this.addNotification(data.data as any)
    });
  }

  private listenToBackGroundMessages() {
    this.notificationService.listenToBackGroundMessages()
    .pipe(takeUntil(this._unsubscribe))
    .subscribe({
      next: (notification) => this.addNotification(notification)
    });
  }

  private observeNotifications() {
    this.notificationService.notifications$
    .pipe(takeUntil(this._unsubscribe))
    .subscribe(notifications => this.notifications = notifications);
  }

  private observeNewNotificationsCount() {
    this.notificationService.newNotificationsCount$
    .pipe(takeUntil(this._unsubscribe))
    .subscribe(newCount => this.newNotificationsCount = newCount);
  }

  private observeTotalNotificationPages() {
    this.notificationService.totalNotificationPages$
    .pipe(takeUntil(this._unsubscribe))
    .subscribe(notificationPages => {
      this.totalPages = notificationPages
    })
  }

  // Add a new notification to the list
  addNotification(notification: NotificationInterface) {
    if (!notification) return;
    
    this.notifications = [notification, ...this.notifications];
    this.newNotificationsCount += 1;
  }

  // Handle click on a notification
  handleNotificationClick(notification: NotificationInterface): any {
    this.markNotificationAsClicked(notification);
    // Perform actions based on the notification type
    switch (notification.type) {
      case 'link': return this.navigateToLink(notification)
    }
  }

  /**
   * Handles the navigation to a link when clicking on a notification.
   * @param notification
   */
  navigateToLink(notification: NotificationInterface) {
    const data = JSON.parse(notification.data);
    if (Array.isArray(data)) {
      this.router.navigate(data);
    } else if (typeof data === 'object') {
      this.router.navigate(data.route, data.options)
    }
  }

  // Mark a notification as clicked
  markNotificationAsClicked(notification: NotificationInterface) {
    if (!notification.id || notification.clicked_at) {
      return;
    }
    this.notificationService.markNotificationAsClicked(notification.id).pipe(takeUntil(this._unsubscribe)).subscribe();
    notification.clicked_at = new Date();
  }

  // Open the notifications panel and mark notifications as seen
  toggleNotificationPanel(event: any) {
    this.notificationPanel.toggle(event);
  }

  openPanel() {
    if(!this.newNotificationsCount) return;

    this.notificationService.markNotificationAsSeen()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe();
  }
  

  loadNextPage() {    
    if(this.totalPages <= this.page) {
      return this.notificationService.setInitNotifications(true);
    }
    
    this.notificationService.setInitNotifications(false);
    this.notificationService.setPage(this.page + 1);
    this.fetchNotifications(false);
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
