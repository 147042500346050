import { Injectable } from '@angular/core';
import { GraphService } from 'src/app/kernel/graphql/services/graph.service';
import {
  IUpdateWorkRecordInput,
  IWorkRecorded,
} from '../../../../../vogo-core/interfaces/work-recorded.interface';
import { BehaviorSubject, first, interval, map, Subscription } from 'rxjs';
import recordedWorkingHoursViewDatashape from '../datashape/recorded-working-hours.view.datashape';

export interface TrackingState {
  isTracking: boolean;
  trackedSeconds: number;
  formattedTime: string;
}

@Injectable({
  providedIn: 'root',
})
export class RecordedWorkingHoursService {
  private trackingStateSubject = new BehaviorSubject<TrackingState>({
    isTracking: false,
    trackedSeconds: 0,
    formattedTime: '',
  });

  private tableSubject = new BehaviorSubject<boolean>(false);

  trackingState$ = this.trackingStateSubject.asObservable();
  tableState$ = this.tableSubject.asObservable();
  
  isTracking = false;
  trackedSeconds = 0;
  formattedTime = '';
  timerSubscription?: Subscription;
  private isFirstTime: boolean = true;

  constructor(private graphService: GraphService) {}

  refreshTable(){
    this.tableSubject.next(true);
  }

  getRecordedWorkingHours(
    id: string,
    datashape = recordedWorkingHoursViewDatashape
  ) {
    return this.graphService
      .constructQuery<{ workRecord: IWorkRecorded }>(
        [datashape],
        'workRecord',
        { id }
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data.workRecord }))
      );
  }

  updateWorkRecord(id: string, input: IUpdateWorkRecordInput) {
    return this.graphService
      .constructMutation<{ updateWorkRecord: IWorkRecorded }>(
        'updateWorkRecord',
        { id: 'ID!', input: 'UpdateWorkRecordInput!' },
        { id, input },
        ['id']
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.updateWorkRecord }))
      );
  }

  startOperatorWork() {
    return this.graphService
      .constructMutation<{ startOperatorWork: IWorkRecorded }>(
        'startOperatorWork',
        {},
        {},
        ['id']
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.startOperatorWork }))
      );
  }

  endOperatorWork() {
    return this.graphService
      .constructMutation<{ endOperatorWork: IWorkRecorded }>(
        'endOperatorWork',
        {},
        {},
        ['id']
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.endOperatorWork }))
      );
  }

  getMyActiveWorkRecord(datashape = recordedWorkingHoursViewDatashape) {
    return this.graphService
      .constructQuery<{ myActiveWorkRecord: IWorkRecorded | null }>(
        [datashape],
        'myActiveWorkRecord'
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.myActiveWorkRecord }))
      );
  }

  initActiveWorkRecord() {
    if (!this.isFirstTime) return;
    this.isFirstTime = false;
    this.getMyActiveWorkRecord().subscribe((res) => {
      if (res.data) {
        this.isTracking = true;
        this.trackedSeconds = Math.ceil(
          (new Date().getTime() - new Date(res.data.startedAt).getTime()) / 1000
        );
        this.updateFormattedTime();
        this.startTimer();
      }
      this.emitTrackingState();
    });
  }

  checkin() {
    this.startOperatorWork().subscribe(() => {
      this.isTracking = true;
      this.startTimer();
      this.emitTrackingState();
    });
  }

  checkout() {
    this.endOperatorWork().subscribe(() => {
      this.isTracking = false;
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.trackedSeconds = 0;
      this.formattedTime = '';
      this.emitTrackingState();
    });
  }

  private startTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timerSubscription = interval(1000).subscribe(() => {
      this.trackedSeconds++;
      this.updateFormattedTime();
      this.emitTrackingState();
    });
  }

  private updateFormattedTime() {
    const hours = Math.floor(this.trackedSeconds / 3600);
    const minutes = Math.floor((this.trackedSeconds % 3600) / 60);
    const seconds = this.trackedSeconds % 60;

    this.formattedTime = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0'),
    ].join(':');
  }

  private emitTrackingState() {
    this.trackingStateSubject.next({
      isTracking: this.isTracking,
      trackedSeconds: this.trackedSeconds,
      formattedTime: this.formattedTime,
    });
  }
}
