import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { EAccessType, EUserType } from 'src/app/vogo-core/enums';
import { DashboardLayoutComponent } from 'src/app/layout/dashboard-layout/dashboard-layout/dashboard-layout.component';

const routes: Route[] = [
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'station',
        loadChildren: () =>
          import('./station/station.module').then(
            ({ StationModule }) => StationModule
          ),
        data: {
          allowedAccessToken: EAccessType.FAT,
          allowedTypes: EUserType.Operator,
        },
      },
      {
        path: 'route',
        loadChildren: () =>
          import('./route/route.module').then(({ RouteModule }) => RouteModule),
        data: {
          allowedAccessToken: EAccessType.FAT,
          allowedTypes: EUserType.Operator,
        },
      },
      {
        path: 'trip',
        loadChildren: () =>
          import('./trip/trip.module').then(({ TripModule }) => TripModule),
        // canActivate: [accessTokenGuard],
        data: {
          allowedAccessToken: EAccessType.FAT,
          allowedTypes: [EUserType.Operator],
        },
      },
      {
        path: 'journeys-log',
        loadChildren: () =>
          import('./journeys-log/journeys-log.module').then(
            ({ JourneysLogModule }) => JourneysLogModule
          ),
        data: {
          allowedAccessToken: EAccessType.FAT,
          allowedTypes: [EUserType.Operator],
        },
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JourneyRoutingModule {}
