<button type="button" class="notification-btn" (click)="toggleNotificationPanel($event)">
  <ng-container
    *ngTemplateOutlet="notificationsTriggerTemplate ? notificationsTriggerTemplate : defaultNotificationsTriggerTemplate"
  ></ng-container>
  <div class="new-notifications-count" *ngIf="newNotificationsCount">{{newNotificationsCount}}</div>
</button>

<sxw-overlay-panel #notificationPanel (onShow)="openPanel()">
  <div class="notifications">
    <ng-container
      *ngTemplateOutlet="notificationsHeaderTemplate ? notificationsHeaderTemplate : defaultNotificationsHeaderTemplate"
    ></ng-container>
    <ul class="notifications__list" *ngIf="notifications.length" infiniteScroll [scrollWindow]="false"
      (scrolled)="loadNextPage()" [infiniteScrollDisabled]="totalPages <= page" [infiniteScrollDistance]="0.5"
    >
      <ng-container *ngFor="let notification of notifications">
        <li class="notification-item" (click)="handleNotificationClick(notification)">
          <ng-container *ngTemplateOutlet="notificationTemplate ? notificationTemplate : defaultNotificationTemplate;
              context: {notification}"
          ></ng-container>
        </li>
      </ng-container>
    </ul>
    <div *ngIf="loading" class="notifications__loading-container">
      <img src="/assets/images/icons/loading-spinner.svg" alt="loading..." class="notifications__loading"/>
    </div>
  </div>
</sxw-overlay-panel>

<ng-template #defaultNotificationsTriggerTemplate>
  <img class="notification-btn__img" src="/assets/images/icons/alarm.svg"/>
</ng-template>

<ng-template #defaultNotificationsHeaderTemplate>
  <h2 class="notifications__header">{{notificationsTitle}}</h2>
</ng-template>

<ng-template #defaultNotificationTemplate let-notification="notification">
  <div class="notification-item__details">
    <b class="notification-item__title">{{ notification?.title }}</b>
    <span class="notification-item__text">{{ notification?.text }}</span>
    <span class="notification-item__date">{{ notification?.created_at | dateAgo }}</span>
  </div>
  <div class="notification-item__seen" *ngIf="!notification?.clicked_at"></div>
</ng-template>
