import { Component, EventEmitter, Host, Input, OnChanges, Optional, Output, SimpleChanges, ViewChild } from "@angular/core";
import { CustomInput } from "../../../../../classes/custom-input";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { getStaticTime } from "src/app/kernel/shared/helpers/date-helper/date.helper";
import { SxwFormComponent } from "src/app/kernel/form/components/sxw-form/sxw-form.component";
import { Calendar } from "primeng/calendar";
import { toUTCDate } from "src/app/kernel/helpers/dateHelper";

@Component({
  selector: "sxw-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatePickerComponent,
      multi: true,
    }
  ],
})
export class DatePickerComponent extends CustomInput<any> implements OnChanges {
  @Input() dateFormat = "dd/mm/yy";
  @Input() readonlyInput = false;
  @Input() override readonly = false;
  @Input() minDate: any = null;
  @Input() maxDate: any = null;
  @Input() showTimePicker: boolean = false;
  @Input() showInputIcon: boolean = true;
  @Input() placeholder: string = "";
  @Input() timePickerPlaceholder: string = "";
  @Input() timePickerLabel!: string;
  @Input() showClearTime:boolean = true
  @Input() theme = "sxw-theme";
  @Input() timePickerTheme = this.theme;
  @Input() timeIcon: boolean = false;
  @Input() useTimeZone:boolean = true;
  @Input() timeStep:number = 5;
  @Input() timeVirtualScroll: boolean = false;
  @Output() select = new EventEmitter();
  @ViewChild(Calendar) calendar!: Calendar;

  icon = "filled-clock";
  time = getStaticTime(0,0,this.timeStep);
  @Input() timeValue: string = "";
  dateValue!: Date | null;
  get _value() {
    if(!this.dateValue) return;
    if (this.showTimePicker && !this.dateValue) {
      return;
    }
    if(!this.timeValue&& this.dateValue){
      this.timeValue = '00:00';
    }
    return this.dateValue
      ? this.showTimePicker
        ? new Date(`${(<Date>this.dateValue).toDateString()} ${this.timeValue}`)
        : this.useTimeZone
        ? this.dateValue.toISOString()
        : toUTCDate(this.dateValue).toISOString()
      : undefined;
  }

  set _value(value) {
    this.extractDateAndTime(value);
  }
  override get summaryValue() {
    const [month,day,year] = Intl.DateTimeFormat("en-US",{ year: 'numeric', month: '2-digit', day: '2-digit' }).format(<Date>this.dateValue).split('/');
    return this._value ? `${day}/${month}/${year} ${this.showTimePicker ? this.timeValue : ''}` :"";
  }
  constructor(@Optional() @Host() public sxwForm?: SxwFormComponent) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['timeStep']){
      this.time = getStaticTime(0,0,this.timeStep);
    }
  }

  handleTimeChange() {
    this._value = "" as any;
    this.onTouched?.();
    this.onChange?.(this._value);
  }

  override writeValue(value: string): void {
    this.convertDateValue(value);
  }

  private convertDateValue(value: any) {
    if (!value) {
      this.dateValue = null
    }

    this.extractDateAndTime(value);
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);

    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  extractDateAndTime(value: string | undefined | Date) {
    if (!value) {
      return;
    }

    // when the date comes in time stamp format
    const date = new Date(value && Number(value) ? +value : value);

    this.dateValue = date;
    this.value = this.dateValue;
    const[hours, minutes] = Intl.DateTimeFormat("en-US", {hour12: false, hour: '2-digit', minute: '2-digit'}).format(date).split(':');

    this.timeValue = this.showTimePicker ? `${hours === '24' ? '00' : hours}:${minutes}` : "";
    return;
  }

  toggle() {
    this.calendar.toggle()
  }
}
