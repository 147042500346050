<sxw-dropdown-input
  [query]="query"
  [options]="!query ? options : []"
  [theme]="theme"
  [(ngModel)]="iconValue"
  [placeholder]="placeholder"
  [readonly]="readonly || disabled"
  (onOverlayClosedEvent)="onTouched()"
  (onDropDownChange)="handleDropDownChanges($event)"
  filterBy="label,iconClass"
  optionValue="label,iconClass"
  [appendTo]="null"
  [showClear]="showClear"
  [virtualScroll]="virtualScroll"
  [virtualScrollOptions]="virtualScrollOptions"
  [ngClass]="{
    'invalid-input': showValidationErrors
  }">
  <ng-template let-icon>
    <div class="icon-item">
      <i [class]="icon.iconClass"></i>
      <span>{{ icon.label }}</span>
    </div>
  </ng-template>
  <ng-template #selectedContent let-icon>
    <div class="icon-item">
      <i [class]="icon.iconClass"></i>
      <span>{{ icon.label }}</span>
    </div>
  </ng-template>
</sxw-dropdown-input>
<sxw-error-message
  *ngIf="showValidationErrors"
  [message]="control!.errors"
></sxw-error-message>
