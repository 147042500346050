import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/layout/dashboard-layout/dashboard-layout/dashboard-layout.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'chat-support',
        loadChildren: () =>
          import('./chat-support/chat-support.module').then(
            ({ ChatSupportModule }) => ChatSupportModule
          ),
      },
      {
        path: 'email-support',
        loadChildren: () =>
          import('./support-emails/support-emails.module').then(
            ({ SupportEmailsModule }) => SupportEmailsModule
          ),
      },
      {
        path: 'suggestions',
        loadChildren: () =>
          import('./suggestions/suggestions.module').then(
            ({ SuggestionsModule }) => SuggestionsModule
          ),
      },
      {
        path: 'waitlist',
        loadChildren: () =>
          import('./waitlist/waitlist.module').then(
            ({ WaitlistModule }) => WaitlistModule
          ),
      },
      {
        path: 'lost-items-requests',
        loadChildren: () =>
          import('./lost-items-requests/lost-items-requests.module').then(
            ({ LostItemsRequestsModule }) => LostItemsRequestsModule
          ),
      },
      {
        path: 'vehicle-malfunction',
        loadChildren: () =>
          import('./vehicle-malfunction/vehicle-malfunction.module').then(
            ({ VehicleMalfunctionModule }) => VehicleMalfunctionModule
          ),
      },
      {
        path: 'route-requests',
        loadChildren: () =>
          import('./route-requests/route-requests.module').then(
            ({ RouteRequestsModule }) => RouteRequestsModule
          ),
      },
      {
        path: 'complaints',
        loadChildren: () =>
          import('./complaint/complaint.module').then(
            ({ ComplaintModule }) => ComplaintModule
          ),
      },
      {
        path: 'leave-of-absence',
        loadChildren: () =>
          import('./leave-of-absence/leave-of-absence.module').then(
            ({ LeaveOfAbsenceModule }) => LeaveOfAbsenceModule
          ),
      },
      {
        path: 'my-leave-of-absence',
        loadChildren: () =>
          import('./my-leave-of-absence/my-leave-of-absence.module').then(
            ({ MyLeaveOfAbsenceModule }) => MyLeaveOfAbsenceModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagementRoutingModule {}
