import { Component, ElementRef, inject, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { SideNavService } from "src/app/layout/side-nav/services/side-nav.service";
import { SideNavStatusEnum } from "src/app/layout/side-nav/interfaces/side-nav-status.enum";
import { DOCUMENT, Location } from '@angular/common';
import { SidenavModel } from "src/app/layout/side-nav/models/sidenav.model";
import { expandNavItemAnimation } from "src/app/layout/side-nav/animations/expand-nav-item.animation";
import { AuthService } from "src/app/vogo-core/services/auth.service";
import { environment } from 'src/environments/environment';
import { ELocalizationLang } from 'src/app/vogo-core/enums';

@Component({
  selector: 'sxw-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [expandNavItemAnimation],
})

// TODO: Refactor this component.
export class SideNavComponent implements OnInit {

  private document = inject(DOCUMENT);

  @Input() width = '303px';
  @Input() agencyName = 'Storex';
  @Input() agencyExtraName = 'Web';
  @Input() agencyNameStyle = 'agency-name';
  @Input() agencyExtraNameStyle = 'agency-extra-name';

  @ViewChild('arrowEl') arrowEl?: ElementRef<HTMLDivElement>
  

  mainLinks = this.sideNavService.mainLinks$;
  selectedMainItem$ = this.sideNavService.getSelectedMainItem();

  sideNavStatusEnum = SideNavStatusEnum;
  sideNavStatus = SideNavStatusEnum.collapse;
  currentUrl = this.location.path();
  canHover = true;

  items = [{ label: 'Logout' }];
  noUser = environment.skip_auth;

  lang = inject(LOCALE_ID) as ELocalizationLang;

  constructor(
    private sideNavService: SideNavService,
    @Inject(Location) private location: Location,
    protected authService: AuthService,
  ) {
  }

  ngOnInit() {
    /**
     * I did that because sidenav re-init in every Loaded module because DashboardLayout component,
     * make ngOnInit run again
     * TODO: Refactor sidenav to be called once in App root
     */
    if (this.sideNavService.currentActivatedMainLink) {
      this.initSideNavStatus();
      return;
    }
    /** .......................................................................................... */
    this.sideNavService.searchMenuActivatedRoute(this.currentUrl);
    this.initSideNavStatus();
  }

  setSelectedItem(id: string | null = null): void {
    this.sideNavService.setSelectedMainLink(id);
  }

  /**
   * change sidebar status to mouseenter or mouseleave
   * @param event
   */
  hoverExpandNavbar(event: MouseEvent): void {
    if (this.sideNavStatus === SideNavStatusEnum.expanded || !this.canHover) return;
    this.sideNavStatus =
    event.type === 'mouseenter'
    ? SideNavStatusEnum.mouseenter
    : SideNavStatusEnum.mouseleave;
    this.canHover = true;
  }

  /**
   * Toggle expanded menu items (open / close them)
   * @param item
   */
  toggleExpandMenuItem(item: SidenavModel): void {
    this.sideNavService.toggleExpandItem(item);
  }

  private initSideNavStatus() {
    this.sideNavStatus = (this.sideNavService.currentActivatedMainLink!)?.items?.length ?
      this.document.body.offsetWidth <= 500 ? SideNavStatusEnum.mouseenter : SideNavStatusEnum.expanded : SideNavStatusEnum.collapse;
    this.canHover = this.sideNavStatus === SideNavStatusEnum.collapse ? true : false
  }
}
