import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/layout/dashboard-layout/dashboard-layout/dashboard-layout.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'settings',
        loadChildren: () =>
          import('./modules/update-settings/update-settings.module').then(
            ({ UpdateSettingsModule }) => UpdateSettingsModule
          ),
      },
      {
        path: 'manual-notifications',
        loadChildren: () =>
          import(
            './modules/manual-notifications/manual-notifications.module'
          ).then(({ ManualNotificationsModule }) => ManualNotificationsModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
