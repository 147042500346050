import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appInfoStateReducers } from 'src/app/core/root-store/root.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GraphqlModule } from 'src/app/kernel/graphql/graphql.module';
import { environment } from 'src/environments/environment';
import { RootEffects } from 'src/app/core/root-store/root.effects';
import { PopupWrapperModule } from '../kernel/popups/popup-wrapper/popup-wrapper.module';
import { SpinnerModule } from '../kernel/shared/modules/spinner/spinner.module';
import { TranslationModule } from '../kernel/translations/modules/translation.module';
import { SideNavService } from 'src/app/layout/side-nav/services/side-nav.service';
import { JWT_OPTIONS, JwtConfig, JwtModule } from '@auth0/angular-jwt';
import { LocalStorageService } from '../kernel/tools/services/local-storage.service';
import { AddPaymentMethodModule } from '../vogo-core/forms/add-payment-method/add-payment-method.module';

export function jwtOptionsFactory(
  localStorageService: LocalStorageService
): JwtConfig {
  return {
    tokenGetter: () => localStorageService.get('token'),
  };
}

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    // ===================== Core Imports { =====================
    GraphqlModule.on(environment.graphql_endpoint),
    // ===================== } Core Imports =====================

    // =================== STATE MANAGEMENT { ===================
    StoreModule.forRoot({ appInfo: appInfoStateReducers }),
    EffectsModule.forRoot([RootEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: false,
      autoPause: true,
    }),
    // =================== } STATE MANAGEMENT ===================
    PopupWrapperModule,
    SpinnerModule,
    TranslationModule.forRoot('/assets/i18n/root/'),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [LocalStorageService],
      },
    }),
    AddPaymentMethodModule.forRoot(),
  ],
  providers: [],
  exports: [PopupWrapperModule, SpinnerModule],
  bootstrap: [],
})
export class CoreModule {
  constructor(sideNavService: SideNavService) {
    sideNavService.setMainLinks([
      {
        id: 'home',
        items: [],
        icon: 'home',
        tooltip: 'sidenav.home',
        direction: 'TOP',
        routerLink: '/',
        routerLinkExact: true,
      },
      {
        id: 'monitoring',
        items: [],
        icon: 'pulse',
        tooltip: 'sidenav.monitoring',
        routerLink: '/monitoring/journeys',
        direction: 'TOP',
        permissions: ['ACTIVE_JOURNEYS_TRACKING']
      },
      {
        id: 'drivers',
        items: [],
        icon: 'driver',
        tooltip: 'sidenav.drivers',
        direction: 'TOP',
        permissions: [],
      },
      {
        id: 'fleet',
        items: [],
        icon: 'fst',
        tooltip: 'sidenav.fleet',
        direction: 'TOP',
        permissions: [],
      },
      {
        id: 'journeys',
        items: [],
        icon: 'map',
        tooltip: 'sidenav.journeys',
        direction: 'TOP',
        permissions: [],
      },
      {
        id: 'crm',
        items: [],
        icon: 'user',
        tooltip: 'sidenav.crm',
        direction: 'TOP',
        permissions: [],
      },
      {
        id: 'finance',
        items: [],
        icon: 'money',
        tooltip: 'sidenav.finance',
        direction: 'TOP',
        permissions: [],
      },
      {
        id: 'management',
        items: [],
        icon: 'management',
        tooltip: 'sidenav.management',
        direction: 'TOP',
        permissions: [],
      },
      // -------------------------------------------------------------

      {
        id: 'setting',
        items: [],
        icon: 'settings',
        tooltip: 'sidenav.setting',
        direction: 'BTN',
      },
    ]);
  }
}
