<p-overlayPanel
  #overlayPanel
  [styleClass]="styleClass"
  (onHide)="onHide.emit(true)"
  (onShow)="onShow.emit(true)"
  [appendTo]="appendTo"
>
  <ng-template pTemplate="content">
    <ng-content></ng-content>
  </ng-template>
</p-overlayPanel>
