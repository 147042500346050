<div class="d-flex justify-content-between container">

  <div class="d-flex align-items-center logo-container">
    <a routerLink="/">
      <img [src]="'/assets/images/logos/Vogo-Logo-' + (lang === 'ar' ? 'ar' : 'en') + '.svg'" alt="logo" class="ng-tns-c80-1">
    </a>
    <div class="dashboard-name"><span> {{ 'adminDashboard' | translate }}  </span></div>
  </div>


  <div class="d-flex align-items-center gap-20">
    <div class="time-tracking-container">
      <sxw-button *ngIf="!isTracking" leftIconFill="#fff" icon="play"  label="checkin" theme="btn primary" (onClick)="recordedWorkingHoursService.checkin()"></sxw-button>
      <sxw-button *ngIf="isTracking" leftIconFill="#fff" icon="pause"  label="checkout" theme="btn primary" (onClick)="recordedWorkingHoursService.checkout()"></sxw-button>
      {{formattedTime}}
    </div>
    <div class="notifications-container">
      <sxw-notifications [notificationsTitle]="'notifications.title' | translate"></sxw-notifications>
    </div>
    <div class="lang" (click)="toggleLang()">
      <span>{{ lang === 'en' ? 'English' : 'العربية' }}</span>
    </div>

    <!-- user-menu-icon -->
    <div #userMenuIcon class="user-menu-icon" (click)="toggleMenu()">
      <img class="default-image" *ngIf="!authService.user$.value?.image" src="assets/images/avatars/man.png" />
      <img *ngIf="authService.user$.value?.image" src="{{authService.user$.value?.image | resource: 'thumb:240x'}}" />
    </div>

    <div class="user-menu" *ngIf="showMenu"  #userMenu  [ngClass]="{'rtl' :  lang!= 'en' } ">
      <div class="user-menu-container">
        <div class="user-info">
          <div class="user-menu-icon">
            <img class="default-image" *ngIf="!authService.user$.value?.image" src="assets/images/avatars/man.png" />
            <img *ngIf="authService.user$.value?.image" src="{{authService.user$.value?.image | resource: 'thumb:240x'}}" />
          </div>
          <div>
            <p class="username"> {{ authService.user$.value?.name }} </p>
            <p style="color:#515151;font-size:12px;"> {{authService.user$.value?.email}} </p>
          </div>
        </div>

        <div class="user-actions">
          <div class="user-action">
            <a routerLink="/dashboard/my-profile">
              <sxw-svg-icon name="user" width="20" height="20" class="icon"></sxw-svg-icon>
              <span> {{ 'topNav.profile' | translate }} </span>
            </a>
          </div>

          <div class="user-action">
            <a (click)="logout()">
              <sxw-svg-icon name="exit" width="20" height="20" class="icon"></sxw-svg-icon>
              <span> {{ 'topNav.logout' | translate }} </span>
            </a>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>
