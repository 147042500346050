import { AfterViewInit, Directive, Input } from '@angular/core';
import { ToggleSideNav } from './toggle-side-nav';

@Directive({
  selector: '[toggleSideNav]'
})
export class ToggleSideNavDirective extends ToggleSideNav implements AfterViewInit {
  @Input('toggleSideNav')
  override sideNav?: HTMLDivElement | undefined;
  
  ngAfterViewInit(): void {
    this.calcActionBtnPosition();  
  }
}
