import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgxHttpLoaderModule } from 'ngx-http-loader';
import { CoreModule } from './core/core.module';
import { MessageModule } from './kernel/tools/message/message.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './vogo-core/interceptors/JwtInterceptor';
import { DashboardModule } from 'src/app/applications/dashboard/dashboard.module';
import { CrmModule } from 'src/app/applications/crm/crm.module';
import { JourneyModule } from 'src/app/applications/journey/journey.module';
import { AuthenticationModule } from 'src/app/applications/authentication/authentication.module';
import { CmModule } from './applications/cm/cm.module';
import { FleetModule } from './applications/fleet/fleet.module';
import { SubscriptionModule } from './applications/subscription/subscription.module';
import { AuthService as AppAuthService } from './vogo-core/services/auth.service';
import { AuthService } from './kernel/tools/services/auth.service';
import { MonitorModule } from './applications/monitor/monitor.module';
import { DashboardLayoutModule } from './layout/dashboard-layout/dashboard-layout.module';
import { NotificationModule } from './kernel/notifications/notification.module';
import { LocalStorageService } from './kernel/tools/services/local-storage.service';
import { loadLocale } from './core/locale';
import { ManagementModule } from './applications/management/management.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SettingsModule } from './applications/settings/settings.module';

// ===================== SUB APPLICATIONS { =====================

// ===================== } SUB APPLICATIONS =====================

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxHttpLoaderModule.forRoot(),
    CoreModule,
    MessageModule,
    BrowserAnimationsModule,
    AuthenticationModule,
    DashboardModule,
    NotificationModule.forRoot(),
    DashboardLayoutModule,
    CrmModule,
    JourneyModule,
    MonitorModule,
    SubscriptionModule,
    CmModule,
    FleetModule,
    ManagementModule,
    SettingsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    { provide: AuthService, useExisting: AppAuthService },
    {
      provide: LOCALE_ID,
      useFactory: loadLocale,
      deps: [LocalStorageService],
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'AED',
    },
    // {provide: APP_INITIALIZER, useFactory: loadGoogleScript, multi: true},
    // { provide: AuthService, useClass: AppAuthService },
    // // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
