import { SidenavItemInterface } from 'src/app/layout/side-nav/interfaces/side-nav-items.interface';

export const managementSidenavConfig: SidenavItemInterface[] = [
  {
    id: 'lostItemsRequests',
    label: 'lostItemsRequests',
    routerLink: '/lost-items-requests',
    permissions: ['VIEW_LOST_ITEM_REQUEST'],
  },
  {
    id: 'complaints',
    label: 'complaints',
    routerLink: '/complaints',
    permissions: ['VIEW_COMPLAINT'],
  },
  {
    id: 'suggestions',
    label: 'suggestions',
    routerLink: '/suggestions',
    permissions: ['VIEW_SUGGESTIONS'],
  },
  {
    id: 'vehicleMalfunction',
    label: 'vehicleMalfunction',
    routerLink: '/vehicle-malfunction',
    permissions: ['VIEW_MALFUNCTION_REPORTS'],
  },
  {
    id: 'supportEmails',
    label: 'supportEmails',
    routerLink: '/email-support',
    permissions: ['VIEW_SUPPORT_EMAIL'],
  },
  {
    id: 'routeRequests',
    label: 'route_requests',
    routerLink: '/route-requests',
    permissions: ['VIEW_ROUTE_REQUEST'],
  },
  {
    id: 'chatSupport',
    label: 'chatSupport',
    routerLink: '/chat-support',
    permissions: ['VIEW_SUPPORT_CHAT', 'SUPPORT_CHAT_AGENT'],
  },
  {
    id: 'leaveOfAbsence',
    label: 'leaveOfAbsence',
    routerLink: '/leave-of-absence',
    permissions: ['VIEW_LEAVE_OF_ABSENCE'],
  },
  {
    id: 'waitlist',
    label: 'waitlist',
    routerLink: '/waitlist',
    permissions: ['VIEW_WAITING_LIST'],
  },
  {
    id: 'myLeaveOfAbsence',
    label: 'myLeaveOfAbsence',
    routerLink: '/my-leave-of-absence',
  },
];
